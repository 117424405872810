import Grid from "@/components/layout/Grid";
import Style from "./Footer.module.scss";
import GridColumn from "@/components/layout/GridColumn";
import Link from "next/link";
import Legal from "./Legal";
import RecentlyViewed from "@/components/product/RecentlyViewed";


async function Footer({ isMobile }){

    return(
        <>
            <Grid>
                <GridColumn start={2} end={14}>
                    <RecentlyViewed />
                </GridColumn>
            </Grid>


            <Grid element="footer" className={Style.block}>
                <GridColumn start={2} end={14}>
                    <Grid md={5}>
                        <details className={Style.article} open={!isMobile}>
                            <summary className={Style.heading} tabIndex="97">
                                My Account
                            </summary>

                            <nav className={Style.links}>
                                {/* <Link href="/c/">Invoices</Link> */}
                                {/* <Link href="/c/">Your Details</Link> */}
                                {/* <Link href="/c/">Order History</Link> */}
                                <Link href="/c/returns">Returns</Link>
                                <Link href="/c/shipping">Shipping</Link>
                                {/* <Link href="/c/">Feedback</Link> */}
                                {/* <Link href="/c/">Site Map</Link> */}
                            </nav>
                        </details>
                        
                        <details className={Style.article} open={!isMobile}>
                            <summary className={Style.heading} tabIndex="98">
                                Help Center
                            </summary>

                            <nav className={Style.links}>
                                {/* <Link href="/c/">Track Orders</Link> */}
                                <Link href="/c/security">Security & Fraud</Link>
                                <Link href="/l/purchase-assurance">Purchase Assurance</Link>

                            </nav>
                        </details>

                        <details className={Style.article} open={!isMobile}>
                            <summary className={Style.heading} tabIndex="99">
                                Company
                            </summary>

                            <nav className={Style.links}>
                                <Link href="/c/about-garden-route-store">About Us</Link>
                                {/* <Link href="/c/">Press Center</Link> */}
                                {/* <Link href="/c/">Sustainability</Link> */}
                                <Link href="/c/contact">Contact & Feedback</Link>
                            </nav>
                        </details>


                        <details className={Style.article} open={!isMobile}>
                            <summary className={Style.heading} tabIndex="99">
                                Stores
                            </summary>

                            <nav className={Style.links}>
                                <Link href="/l/become-a-vendor">
                                    Become a Vendor
                                </Link>

                                <Link href="/c/verified-suppliers">
                                    Verified Suppliers
                                </Link>

                                {/* <Link href="/stores/faq">
                                    Store FAQ
                                </Link> */}

                                {/* <Link href="/stores/locator">
                                    Store Locator
                                </Link> */}

                                {/* <Link href="/c/">
                                    Deliver For GardenRoute.store
                                </Link> */}
                            </nav>
                        </details>
                    </Grid>
                </GridColumn>

                <Legal />            
            </Grid>
        </>
    )
}

export default Footer;