import clsx from "clsx";
import Style from "./TopHat.module.scss";


function TopHat({ theme }){
    return(
        <aside className={clsx(Style.block, Style[theme])}>
            <p className={Style.text}>
                <strong>Save -999%</strong> on all eligible products using the promo code: <strong>GARDENROUTE2024</strong>
            </p>
        </aside>
    )
}

export default TopHat;