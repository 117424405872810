import Link from "next/link";
import Style from "./Footer.module.scss";
import GridColumn from "@/components/layout/GridColumn";


function Legal(){
    return(
        <GridColumn start={1} end={16} className={Style.legal}>
            <nav className={Style.legalText}>
                <Link href="/c/legal/terms" className={Style.legalLink}>
                    Terms &amp; Conditions
                </Link>

                <Link href="/c/legal/privacy" className={Style.legalLink}>
                    Privacy Policy
                </Link>

                <Link href="/c/" className={Style.legalLink}>
                    Human Rights
                </Link>

                <Link href="/c/" className={Style.legalLink}>
                    See Something, Say Something.
                </Link>
            </nav>

            <small>
                &copy; {(new Date()).getFullYear()} GardenRoute.store (Pty) Ltd.  All rights reserved.
            </small>

            <small>
                Accepted Payments: Visa, Mastercard, Apple Pay, American Express, Diners Club International, PayFast, Payflex, Discovery Miles, Ozow, Instant EFT.
            </small>
        </GridColumn>
    )
}

export default Legal;