import Style from "./Logo.module.scss";
import DefaultLogo from "@/public/assets/logo.svg"
import logoDarkTheme from "@/public/assets/logo-white.svg"
import Image from "next/image";
import clsx from "clsx";


function Logo({ theme, className }){
    return(
        <Image 
            src={theme === "dark" ? logoDarkTheme : DefaultLogo} 
            width={300} 
            height={50} 
            alt="Garden Route Store" 
            className={clsx(Style.block, className)}
        />
    )
}

export default Logo;